.Footer.section
	background-image: url(./assets/bg-d-min.jpeg)
	background-size: cover
	background-position: center
	padding: 2.24vw 0
	padding-left: 15.83vw

	.section-content
		display: flex

		.logo
			cursor: pointer

	.nav.landing
		margin-right: 5.52vw

		img
			display: block
			width: 8.39vw

	.nav-groups
		display: flex

		.nav-group
			margin-right: 4.17vw

			&:last-of-type
				margin-right: 0

		.heading
			font-weight: 700
			color: #B11AFF
			font-size: 1.04vw
			line-height: 1.25vw
			text-transform: uppercase
			margin-bottom: 1.04vw

		.nav
			a
				color: white
				text-decoration: none
				font-weight: 500
				font-size: 0.83vw
				line-height: 1.04vw
				letter-spacing: -0.015em
				opacity: 0.9

		.navs
			display: flex

			.nav
				margin-bottom: 0.83vw
				cursor: pointer

				&:last-of-type
					margin-bottom: 0

			.left
				min-width: 4.69vw

		.links
			display: flex
			margin-left: -0.52vw
			margin-top: -0.52vw
			cursor: pointer

			@media screen and (max-width: 1023px)
				width: 100vw
				justify-content: center
				margin-top: 8.46vw

			a
				display: block
				padding: 0.52vw
				position: relative
				height: 2.60vw

				&:hover
					img.logo
						opacity: 0

					img.hover
						opacity: 1

					.shadow
						opacity: 1

				@media screen and (max-width: 1023px)
					padding: 2.56vw

					&:hover
						img.logo
							opacity: 1

						img.hover
							opacity: 0

						.shadow
							opacity: 0

				img
					display: block
					width: 1.56vw
					transition: all 100ms $bezier

					@media screen and (max-width: 1023px)
						width: 7.69vw

				img.hover
					position: absolute
					left: 0.52vw
					top: 0.52vw
					border-radius: 50%
					opacity: 0

					@media screen and (max-width: 1023px)
						left: 2.56vw
						top: 2.56vw

				.shadow
					width: 1.56vw
					height: 1.56vw
					position: absolute
					border-radius: 50%
					left: 0.52vw
					top: 0.52vw
					box-shadow: 0 0 60px rgba(255, 255, 255, 0.7)
					transition: all 100ms $bezier
					opacity: 0

.isPadLandscape .Footer.section, .isPadPortrait .Footer.section
	background-image: url(./assets/bg-pad-min.jpeg)
	padding: 4.20vw 0
	padding-left: 0

	.section-content
		justify-content: center

	.nav.landing
		margin-right: 4.10vw

		img
			width: 15.63vw

	.nav-groups
		.nav-group
			margin-right: 3.91vw

		.heading
			font-size: 1.95vw
			line-height: 2.34vw
			margin-bottom: 1.95vw

		.nav
			a
				font-size: 1.56vw
				line-height: 1.95vw

		.navs
			.nav
				margin-bottom: 1.56vw

			.left
				min-width: 8.79vw

		.links
			margin-left: -0.98vw
			margin-top: -0.98vw

			a
				padding: 0.98vw

			img
				width: 2.93vw

.isMobile .Footer.section
	background-image: url(./assets/bg-mobile-min.jpg)
	min-height: 153.08vw
	padding: 0
	padding-top: 5vw

	.section-content
		display: block

	.nav.landing
		margin-right: 0

		img
			width: 36.15vw
			margin: 0 auto

	.nav-groups
		display: block
		padding-left: 8.46vw
		margin-top: 6.41vw

		.nav-group
			margin-right: 0
			margin-bottom: 7.69vw

		.heading
			font-size: 4.62vw
			line-height: 5.64vw
			margin-bottom: 2.56vw

		.nav
			a
				font-size: 4.10vw
				line-height: 5.13vw

		.navs
			.nav
				margin-bottom: 4.10vw

			.left
				min-width: 23.08vw

		.links
			margin-top: 0
			justify-content: flex-start

			a
				padding: 2.56vw

			img
				width: 7.69vw
