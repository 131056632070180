.About.section
	background-image: url(./assets/bg-d-min.jpeg)
	background-size: cover
	background-position: center
	color: white

	.section-content
		width: 58.33vw
		margin: 0 auto

	.flex-title
		display: flex
		width: 22.92vw

		.t1
			font-weight: 500
			color: #B11AFF
			font-size: 1.56vw
			line-height: 1.93vw
			margin-right: 0.52vw

		.line
			height: 0.05vw
			background-color: #B11AFF
			flex-grow: 1
			margin-top: 0.9vw

	.t2
		margin-top: 0.21vw
		font-size: 2.34vw
		font-weight: 700
		line-height: 2.86vw

	.t3
		font-size: 1.04vw
		letter-spacing: 0.05em
		line-height: 1.25vw
		margin-top: 1.82vw

	p
		font-size: 0.83vw
		line-height: 150%
		letter-spacing: 0.05em
		margin: 1.04vw 0

	.button-wrap
		display: flex
		justify-content: center
		margin-top: 3.33vw
		flex-wrap: wrap

		.button
			display: block

			img
				width: 15.63vw

	.t4
		width: 100%
		text-align: center
		margin-top: 0.52vw
		letter-spacing: 0.05em
		font-size: 0.94vw

.isPadLandscape .About.section
	.section-content
		width: 87.89vw

	.flex-title
		width: 42.97vw

		.t1
			font-size: 2.93vw
			line-height: 3.61vw
			margin-right: 0.98vw

		.line
			height: 0.10vw
			margin-top: 1.8vw

	.t2
		margin-top: 0.39vw
		font-size: 4.39vw
		line-height: 5.37vw

	.t3
		font-size: 1.46vw
		line-height: 1.76vw
		margin-top: 2.15vw

	p
		font-size: 1.27vw
		line-height: 1.95vw
		margin: 2vw 0

	.button-wrap
		margin-top: 0.98vw

		.button
			img
				width: 24.41vw

	.t4
		margin-top: 0.78vw
		font-size: 1.46vw

.isPadPortrait .About.section
	background-image: url(./assets/bg-portrait-min.jpg)

	.section-content
		width: 75.00vw

	.flex-title
		width: 42.97vw

		.t1
			font-size: 2.93vw
			line-height: 3.61vw
			margin-right: 0.98vw

		.line
			height: 0.10vw
			margin-top: 1.8vw

	.t2
		margin-top: 0.39vw
		font-size: 4.39vw
		line-height: 5.37vw

	.t3
		font-size: 1.95vw
		line-height: 2.34vw
		margin-top: 2.93vw

	p
		font-size: 1.56vw
		line-height: 2.34vw
		margin: 3vw 0

	.button-wrap
		margin-top: 7.81vw

		.button
			img
				width: 29.30vw

	.t4
		margin-top: 0.98vw
		font-size: 1.76vw

.isMobile .About.section
	background-image: url(./assets/bg-mobile-min.jpg)
	min-height: 208.21vw

	.section-content
		width: 87.18vw

	.flex-title
		width: 87.18vw

		.t1
			font-size: 5.13vw
			line-height: 6.15vw
			margin-right: 2.05vw

		.line
			height: 0.26vw
			margin-top: 3vw

	.t2
		margin-top: 1.03vw
		font-size: 7.69vw
		line-height: 9.49vw

	.t3
		font-size: 3.33vw
		line-height: 4.36vw
		margin-top: 5.13vw

	p
		font-size: 3.33vw
		line-height: 4.36vw
		margin: 5vw 0

	.button-wrap
		margin-top: 0

		.button
			img
				width: 57.44vw

	.t4
		margin-top: 2.05vw
		font-size: 3.33vw
