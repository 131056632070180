.LHC.section
	background-image: url(./assets/bg-d-min.jpeg)
	background-size: cover
	background-position: center

	.title
		background-image: url(./assets/title-bg.svg)
		background-repeat: no-repeat
		background-position: center
		background-size: 44.32vw auto

	.main-content
		width: 68.13vw
		margin: 0.47vw auto
		margin-bottom: 1.2vw
		position: relative

		img
			display: block
			width: 100%

		img.blur
			position: absolute
			height: auto
			width: 29.43vw
			left: calc(50% - 14.72vw)
			top: -3.65vw

		.inner-content
			position: relative

			img.desktop
				display: block
				width: 100%
				margin: 0 auto

			.text-wrap
				position: absolute

				.t1
					color: #8EFFE1
					font-weight: 800
					font-size: 1.56vw
					line-height: 1.93vw
					text-shadow: 0px 0px 0.42vw #D04BFF

				.t2
					width: 15.63vw
					margin-top: 0.52vw
					font-size: 0.83vw
					letter-spacing: 0.05em
					line-height: 1.25vw

			.wrap1
				left: 2%
				top: 21%

			.wrap2
				left: 6%
				top: 64%

			.wrap3
				left: 77%
				top: 7%

			.wrap4
				left: 77%
				top: 59%

	.button-wrap-area
		display: flex
		align-items: flex-start
		justify-content: center

		.buy-sell-area
			display: flex
			align-items: center
			justify-content: space-between
			margin-bottom: 1vh

			.buy-sell-image
				cursor: pointer

				&:first-child
					padding-right: 1vw

				&-disabled
					cursor: not-allowed

		.button-wrap
			display: flex
			flex-direction: column
			position: relative
			padding: 0 10px

			.button
				position: relative
				display: block
				margin-bottom: 1vh

				img
					cursor: pointer

			.t4
				width: 100%
				text-align: center
				margin-top: 0.52vw
				letter-spacing: 0.05em
				font-size: 0.94vw
				word-break: break-all

			&-addLHC
				justify-content: flex-end

.isPadLandscape .LHC.section
	.title
		background-image: none

	.main-content
		width: 100vw
		margin-top: 0.66vw

		img.blur
			width: 40vw
			left: calc(50% - 20vw)
			top: -3.65vw

		.inner-content
			.text-wrap
				.t1
					font-size: 2.34vw
					line-height: 2.83vw
					text-shadow: 0 0 0.68vw #D04BFF

				.t2
					width: 23.05vw
					margin-top: 0.78vw
					font-size: 1.27vw
					line-height: 1.86vw

			.wrap1
				left: 2%
				top: 23%

			.wrap2
				left: 6%
				top: 66%

			.wrap3
				left: 77%
				top: 7%

			.wrap4
				left: 76.5%
				top: 59%

	.button-wrap
		margin-top: 1vw

	.t4
		margin-top: 0.78vw
		font-size: 1.46vw

.isPadPortrait .LHC.section
	background-image: url(./assets/bg-portrait-min.jpg)

	.title
		background-image: none

	.main-content
		width: 100vw
		margin-top: 1.95vw

		img.blur
			display: none

		.inner-content
			img.desktop
				display: none

			img.portrait
				display: block

			.text-wrap
				width: 19.53vw

				.t1
					font-size: 2.93vw
					line-height: 3.61vw
					text-shadow: 0 0 0.78vw #D04BFF

				.t2
					width: 100%
					margin-top: 0.98vw
					font-size: 1.56vw
					line-height: 2.34vw

			.wrap1
				left: 4%
				top: 7%

			.wrap2
				left: 4%
				top: 63.5%

			.wrap3
				left: 76.5%
				top: 8.5%

			.wrap4
				left: 76.5%
				top: 63%

	.button-wrap
		margin-top: -13vw

	.t4
		margin-top: 0.98vw
		font-size: 1.76vw

.isMobile .LHC.section
	background-image: url(./assets/bg-mobile-min.jpeg)
	min-height: 276.67vw

	.title
		background-image: none

	.main-content
		width: 100vw
		margin-top: 5vh

		img.blur
			display: none

		.inner-content
			padding-left: 15.13vw

			img.desktop
				display: none

			img.portrait
				display: none

			.text-wrap
				width: 79.49vw
				position: static

				.t1
					font-size: 5.13vw
					line-height: 6.15vw
					text-shadow: 0 0 2.05vw #D04BFF
					position: relative

					&::before
						content: ''
						background: url(./assets/LHCItemBg.png) no-repeat center
						background-size: contain
						width: 80px
						height: 80px
						position: absolute
						display: block
						left: -46px
						top: -15px
						z-index: -1

				.t2
					width: 100%
					margin-top: 2.05vw
					font-size: 3.59vw
					line-height: 5.38vw

			.wrap1
				margin-bottom: 11.28vw

			.wrap2
				margin-bottom: 10.51vw

			.wrap3
				margin-bottom: 8.72vw

	.button-wrap-area
		display: flex
		flex-direction: column
		align-items: center
		margin-top: 5vh
		padding: 0px 4vw

		.button-wrap
			margin-top: 0
			max-width: 100%
			justify-content: center

		.t4
			margin-top: 2.56vw
			font-size: 3.59vw

	.LHCLogo
		text-align: center
		margin: 2vh 0 1vh 0

		.mobile-lhc
			max-width: 240px

	.buy-sell-area
		display: grid
		align-items: center
		justify-content: space-between
		grid-template-columns: 1fr
		grid-gap: 20px
		padding: 10px 10vw
		max-width: 300px
		margin: 0 auto
