.Roadmap.section
	background-image: url(./assets/bg-d-min.jpeg)
	background-size: cover
	background-position: center

	.progress-wrap
		display: flex
		justify-content: center
		margin-top: 3.13vw

		.milestone
			width: auto
			height: 2.60vw
			// background-image: url(./assets/milestone-inactive.svg)
			background-size: 2.08vw auto
			background-position: center
			background-repeat: no-repeat
			position: relative
			display: flex
			align-items: center
			white-space: pre
			font-weight: 700
			color: #a3a3a3
			text-align: center
			line-height: 4.32vw
			font-size: 2.13vw

			&.active
				color: #8EFFE1

			&.animate.active .active-item
				opacity: 1

			.active-item
				position: absolute
				width: 100%
				height: 100%
				// background-image: url(./assets/milestone-active.svg)
				background-size: contain
				opacity: 0
				top: 0
				left: 0
				transition: all 500ms linear
		
			.phase.active
				text-shadow: 0 0 0.94vw #d04bff


		.m1.milestone .active-item
			transition-delay: 1000ms

		.m2.milestone .active-item
			transition-delay: 1800ms

		.m3.milestone .active-item
			transition-delay: 2600ms

		.m4.milestone .active-item
			transition-delay: 3400ms

		.m5.milestone .active-item
			transition-delay: 4200ms

		.line
			width: 4.64vw
			height: 0.16vw
			margin: 1.20vw 1.04vw
			background-color: rgba(#D482FF, 0.2)
			position: relative

			&.animate.active .active-item
				width: 100%

			.active-item
				position: absolute
				left: 0
				top: 0
				width: 0
				height: 100%
				background-color: #8EFFE1
				transition: all 500ms linear

		.l1.line .active-item
			transition-delay: 1300ms

		.l2.line .active-item
			transition-delay: 2100ms

		.l3.line .active-item
			transition-delay: 2900ms

		.l4.line .active-item
			transition-delay: 3700ms

	.milestones
		display: flex
		justify-content: center
		margin-top: 2.2vh

		.item
			margin: 0 1.40vw
			width: 13.26vw

			.list
				margin: 0

				&__item
					margin-bottom: 1vh

			.percent
				font-size: 3.13vw
				font-weight: 700
				color: #8EFFE1
				text-align: center
				line-height: 4.32vw

				&.active
					text-shadow: 0 0 0.94vw #D04BFF

				span
					font-weight: 800
					font-size: 1.82vw
					margin-left: 0.47vw

			.type
				background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.46%, rgba(255, 255, 255, 0.024) 100%)
				border: 0.10vw solid rgba(255, 255, 255, 0.28)
				box-sizing: border-box
				backdrop-filter: blur(12px)
				border-radius: 0.52vw
				line-height: 1.77vw
				text-align: center
				color: #8EFFE1
				font-weight: 700
				font-size: 0.94vw
				font-style: italic
				margin-top: 0.52vw

			.text
				margin-top: 0.83vw
				font-size: 0.83vw
				letter-spacing: 0.05em
				line-height: 150%

.isPadLandscape .Roadmap.section
	.progress-wrap
		margin-top: 4.49vw

		.milestone
			// width: 5.32vw
			height: 3.91vw
			background-size: 3.13vw auto

		.line
			width: 6vw
			height: 0.20vw
			margin: 1.8vw 1.56vw

	.milestones
		.item
			margin: 0 2vw
			width: 14.48vw

			.list
				padding-left: 30px

			.percent
				font-size: 4.79vw
				line-height: 6.54vw

				&.active
					text-shadow: 0 0 1.46vw #D04BFF

				span
					font-size: 2.73vw
					margin-left: 0.68vw

			.type
				border: 0.20vw solid rgba(255, 255, 255, 0.28)
				line-height: 2.64vw
				font-size: 1.46vw
				margin-top: 0.78vw
				border-radius: 0.98vw

			.text
				margin-top: 1.27vw
				font-size: 1.27vw
				line-height: 1.95vw

.isPadPortrait .Roadmap.section
	background-image: url(./assets/bg-portrait-min.jpg)

	.progress-wrap
		margin-top: 3.91vw

		.milestone
			width: 3.32vw
			height: 3.91vw
			background-size: 3.13vw auto

		.line
			width: 14.65vw
			height: 0.20vw
			margin: 1.8vw 1.56vw

	.milestones
		margin-top: 2.64vw

		.item
			margin: 0 0.49vw
			width: 17.48vw

			.percent
				font-size: 4.79vw
				line-height: 6.54vw

				&.active
					text-shadow: 0 0 1.46vw #D04BFF

				span
					font-size: 2.73vw
					margin-left: 0.68vw

			.type
				border: 0.20vw solid rgba(255, 255, 255, 0.28)
				line-height: 2.64vw
				font-size: 1.46vw
				margin-top: 0.78vw
				border-radius: 0.98vw

			.text
				margin-top: 1.27vw
				font-size: 1.27vw
				line-height: 1.95vw

.isMobile 

	.section
		padding: 2vh 0

	.Roadmap.section
		background-image: url(./assets/bg-mobile-min.jpg)
		min-height: 283.33vw
		border-bottom: 1px solid #21073f

		.mobile-flex
			display: flex
			justify-content: center
			margin-top: 6.15vw
			padding-left: 5.90vw

		.progress-wrap
			margin-top: 0
			margin-right: 6.67vw
			flex-direction: column
			justify-content: flex-start

			.milestone
				width: auto
				height: auto
				background-size: contain
				flex-direction: column
				font-size: 3.2vw
				width: 300px
				align-items: flex-start

				.phase
					font-size: 5vw
					margin-bottom: 2vh

				.list
					color: #FFFFFF
					margin: 0
					margin-left: 10vw
					text-align: left
					width: 300px
					margin-bottom: 3vh

			.line
				display: none
				width: 0.77vw
				margin: 1vw 4.36vw

				&.animate.active .active-item
					height: 100%

				.active-item
					width: 100%
					height: 0

			.l1
				height: 27vw

			.l2
				height: 39vw

			.l3
				height: 17vw

			.l4
				height: 49.5vw

		.milestones
			margin-top: 0
			flex-direction: column
			justify-content: flex-start

			.item
				margin: 0
				margin-bottom: 5.13vw
				width: 68.46vw

				.top-flex
					display: flex

				.percent
					font-size: 7.69vw
					line-height: 9.23vw

					&.active
						text-shadow: 0 0 3.33vw #D04BFF

					span
						font-size: 6.15vw
						margin-left: 1.79vw

				.type
					border: 0.51vw solid rgba(255, 255, 255, 0.28)
					line-height: 8.21vw
					font-size: 4.10vw
					margin-top: 0
					margin-left: 2.56vw
					height: 9.23vw
					width: 47.95vw
					border-radius: 2.56vw

				.text
					margin-top: 2.56vw
					font-size: 3.59vw
					line-height: 5.38vw
