#Navigation
	display: flex
	position: fixed
	top: 0
	left: 0
	z-index: 10
	justify-content: space-between
	padding: 0 2.60vw
	width: 100vw

	@media screen and (max-width: 1023px)
		padding: 0 0 0 4.10vw
		background: rgba(16, 0, 35, 0.7)
		box-shadow: 0px 1.03vw 1.03vw rgba(0, 0, 0, 0.25)
		backdrop-filter: blur(10px)
		height: 12.82vw

		&.no-blur
			backdrop-filter: none

	.left-wrap
		display: flex

	#Navs
		display: flex
		// padding: 0.78vw 0
		flex-grow: 1
		justify-content: space-between

		&.webSite
			padding: 0.78vw 0

		.nav-link-areas
			display: flex
			justify-content: space-between
			width: 100%

		@media screen and (max-width: 1023px)
			padding: 0

		.navigation-links,
		.nav-link-areas
			display: flex
			flex-grow: 1

			@media screen and (max-width: 1023px)
				display: block

			.playBtn
				position: relative
				width: 8vw
				top: 0.6vh

		.nav-links
			display: flex
			flex-grow: 1

			@media screen and (max-width: 1023px)
				display: block
				position: fixed
				top: 0
				left: 0
				height: 100vh
				height: calc(var(--vh, 1vh) * 100)
				background-image: url(./assets/mobile-bg-min.jpeg)
				background-size: cover
				background-position: center
				width: 100vw
				z-index: 10
				opacity: 0
				visibility: hidden
				transition: all 300ms $bezier
				padding-top: 12.67vw
				padding-bottom: 12.67vw
				overflow-y: auto

				&.show
					opacity: 1
					visibility: visible

				.navigation-links
					min-height: 118vw

		.nav
			margin: 0 0.78vw
			display: flex

			&:hover a
				text-shadow: 0 0 10px rgba(215, 81, 206, 0.25), 0 0 15px #D751CE

				@media screen and (max-width: 1023px)
					text-shadow: none

			&.active a
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #F267EB 0%, #7F4CEF 100%)
				background-clip: text
				text-fill-color: transparent
				-webkit-background-clip: text
				-webkit-text-fill-color: transparent

				@media screen and (max-width: 1023px)
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #F267EB 0%, #7F4CEF 100%)
					background-clip: text
					text-fill-color: transparent
					-webkit-background-clip: text
					-webkit-text-fill-color: transparent
					display: block

			@media screen and (max-width: 1023px)
				margin: 0

			.nav-logo-back-btn
				display: none

				.backToWebSiteImg
					top: 0
					width: 15vw

					@media screen and (max-width: 1023px)
						margin: 0 10px
						width: 32.67vw

				@media screen and (max-width: 1023px)
					display: flex

			a:not(.backToWebBtn)
				text-decoration: none
				color: white
				line-height: 3.13vw
				font-size: 0.94vw
				font-weight: 500
				transition: all 100ms $bezier
				cursor: pointer
				user-select: none

				@media screen and (max-width: 1023px)
					font-size: 4.62vw
					line-height: 10.77vw
					margin-bottom: 2.56vw
					text-align: center
					width: 100%
					display: block

		.nav.landing
			@media screen and (max-width: 1023px)
				margin: 0

			.logo-a
				cursor: pointer

			.logo-a,
			img.logo
				width: 4.58vw
				display: flex
				align-items: center
				justify-content: center

				@media screen and (max-width: 1023px)
					width: 16.15vw
					margin-top: 1.03vw

		.mint-button
			display: none

			@media screen and (max-width: 1023px)
				display: flex
				justify-content: center

			a.mint
				width: 53.85vw
				height: 13.33vw
				background-position: center
				background-repeat: no-repeat
				background-size: contain

				&.show
					background-image: url(./assets/mint-active.svg)

				&.inactive
					background-image: url(./assets/mint-inactive.svg)

				&.coming-soon
					background-image: url(./assets/mint-coming-soon.svg)

	.links
		display: flex
		margin-top: 0.26vw
		cursor: pointer
		padding: 0.78vw 0

		.lineImg
			width: 1px
			height: 2vw
			margin: 0 4px
			display: none
			position: relative
			top: 4px

		&.webSite
			margin-top: 0
			padding: 0
			padding-top: 0.26vw

			.lineImg
				display: block

		@media screen and (max-width: 1023px)
			width: 100vw
			justify-content: center
			margin-top: 8.46vw

		a,
		.i18nBtn
			display: block
			padding: 0.52vw
			position: relative
			height: 2.60vw
			cursor: pointer

			&:hover
				.logo-a,
				img.logo:not(.i18nBtnImg)
					opacity: 0

				img.hover
					opacity: 1

				.shadow
					opacity: 1

			@media screen and (max-width: 1023px)
				padding: 2.56vw

				&:hover
					.logo-a,
					img.logo
						opacity: 1

					img.hover
						opacity: 0

					.shadow
						opacity: 0

			img
				display: block
				width: 1.56vw
				transition: all 100ms $bezier

				@media screen and (max-width: 1023px)
					width: 7.69vw
			
			.i18nBtnImg
				width: 2.5vw

			img.hover
				position: absolute
				left: 0.52vw
				top: 0.52vw
				border-radius: 50%
				opacity: 0

				@media screen and (max-width: 1023px)
					left: 2.56vw
					top: 2.56vw

			.shadow
				width: 1.56vw
				height: 1.56vw
				position: absolute
				border-radius: 50%
				left: 0.52vw
				top: 0.52vw
				box-shadow: 0 0 60px rgba(255, 255, 255, 0.7)
				transition: all 100ms $bezier
				opacity: 0

	.others
		display: flex

		.button
			width: 11.72vw
			height: 5.52vw
			background-image: url(./assets/button-bg-min.png)
			background-size: contain
			background-repeat: no-repeat
			position: relative
			cursor: pointer

			@media screen and (max-width: 1023px)
				width: 36.67vw
				height: 12.82vw
				background-size: 40vw auto
				background-position: 2vw -2vw

			.text
				position: absolute
				font-size: 1.04vw
				font-weight: 600
				background: linear-gradient(91.9deg, #FFFFFF -16.34%, #E281FF 70.18%, #D752FF 102.87%, #FBEFFF 102.87%)
				-webkit-background-clip: text
				-webkit-text-fill-color: transparent
				background-clip: text
				text-fill-color: transparent
				width: 7.81vw
				top: 1.82vw
				left: 2vw
				text-align: center

				&:hover
					background: linear-gradient(91.9deg, #D752FF -16.34%, #E281FF 70.18%, #D752FF 102.87%, #FBEFFF 102.87%)
					-webkit-background-clip: text
					-webkit-text-fill-color: transparent
					background-clip: text
					text-fill-color: transparent
					text-shadow: 0px 0px 20px #FFFFFF

				&.glow
					background: linear-gradient(91.9deg, #FFFFFF -16.34%, #7a717c 70.18%, #D752FF 102.87%, #FBEFFF 102.87%)
					-webkit-background-clip: text
					-webkit-text-fill-color: transparent
					background-clip: text
					text-fill-color: transparent
					text-shadow: 0px 0px 20px #FFFFFF

				@media screen and (max-width: 1023px)
					font-size: 3.59vw
					z-index: 1
					left: 11.2vw
					top: 4.2vw
					width: 20.51vw

					&.glow
						text-shadow: 0 0 3.59vw #FFFFFF

		.mobile-menu
			display: none
			width: 12.82vw
			height: 12.82vw
			position: relative

			@media screen and (max-width: 1023px)
				display: block
				z-index: 11

			img
				display: block
				width: 100%
				position: absolute
				top: 0
				left: 0
				transition: all 300ms $bezier
				opacity: 0

				&.active
					opacity: 1

	.dialogue
		position: fixed
		width: 100vw
		height: 100vh
		top: 0
		left: 0
		background-color: rgba(0, 0, 0, 0.5)
		backdrop-filter: blur(70px)
		transition: all 200ms $bezier
		visibility: hidden
		opacity: 0
		display: flex
		flex-direction: column
		justify-content: center

		&.active
			visibility: visible
			opacity: 1

			.center-wrap
				opacity: 1
				transform: scale(1)

		@media screen and (max-width: 1023px)
			display: flex
			padding-top: 0
			flex-direction: column
			justify-content: center
			align-items: center
			z-index: 12

		.center-wrap
			width: 26.04vw
			box-shadow: 0 0.16vw 0.52vw rgba(0, 0, 0, 0.6)
			border-radius: 1.04vw
			margin: 0 auto
			position: relative
			transition: all 400ms $bezier
			transform: scale(0.95)
			opacity: 0

			@media screen and (max-width: 1023px)
				width: 65.13vw
				box-shadow: 0 0.77vw 2.56vw rgba(0, 0, 0, 0.6)
				border-radius: 5.13vw

		.head
			height: 5.36vw
			background-size: contain
			background-repeat: no-repeat
			background-position: center
			position: relative
			border-top-left-radius: 1.04vw
			border-top-right-radius: 1.04vw

			&.purple
				background-image: url(./assets/bg-purple-d.svg)

			&.blue
				background-image: url(./assets/bg-blue-d.svg)

			@media screen and (max-width: 1023px)
				height: 16.92vw
				border-top-left-radius: 5.13vw
				border-top-right-radius: 5.13vw

				&.purple
					background-image: url(./assets/bg-purple-m.svg)

				&.blue
					background-image: url(./assets/bg-blue-m.svg)

			img
				width: 10.26vw
				position: absolute
				bottom: 0
				left: calc(50% - 5.13vw)

				@media screen and (max-width: 1023px)
					width: 30.00vw
					left: calc(50% - 15vw)

		.content
			background-color: white
			border-bottom-left-radius: 1.04vw
			border-bottom-right-radius: 1.04vw
			padding: 1.82vw 0.78vw 1.56vw 0.78vw

			@media screen and (max-width: 1023px)
				border-bottom-left-radius: 5.13vw
				border-bottom-right-radius: 5.13vw
				padding: 3.59vw 3.59vw 4.10vw 3.59vw

		.title
			text-align: center
			font-size: 1.56vw
			font-weight: 700

			@media screen and (max-width: 1023px)
				font-size: 5.13vw

			img
				display: inline-block
				height: 2.14vw

				@media screen and (max-width: 1023px)
					height: 7.18vw

		.text
			text-align: center
			margin-top: 0.52vw
			font-size: 1.04vw
			font-weight: 500
			color: #A3A3A3

			@media screen and (max-width: 1023px)
				margin-top: 1.54vw
				font-size: 4.10vw

			span
				font-weight: 700

		.button-wrap
			text-align: center
			margin-top: 1.04vw

			@media screen and (max-width: 1023px)
				margin-top: 4.10vw

			.button
				display: inline-block
				cursor: pointer

				img
					width: 10.42vw
					display: block

					@media screen and (max-width: 1023px)
						width: 34.87vw

		.close
			width: 2.81vw
			height: 2.71vw
			display: flex
			flex-direction: column
			justify-content: center
			align-items: center
			position: absolute
			top: 0
			right: 0
			cursor: pointer

			@media screen and (max-width: 1023px)
				width: 11.03vw
				height: 11.03vw

			img
				display: block
				width: 2.29vw

				@media screen and (max-width: 1023px)
					width: 8.97vw

.isPadLandscape #Navigation, .isPadPortrait #Navigation
	padding: 0 0 0 0.68vw

	#Navs
		padding: 0.78vw 0

		.nav
			margin: 0 0.98vw

			a
				line-height: 4.88vw
				font-size: 1.37vw

		.nav.landing
			.logo-a,
			img.logo
				width: auto
				height: 4.88vw

		.links
			padding-top: 1vw

		.navigation-links
			.playBtn
				top: 1vh
				width: 10vw

	.others
		.links
			padding-top: 1.04vw

			a
				padding: 0.78vw

				img
					width: 2.54vw

				img.hover
					left: 0.78vw
					top: 0.78vw

		.button
			width: 15vw
			height: 8vw

			.text
				font-size: 1.46vw
				width: 10vw
				top: 2.2vw
				left: 2.4vw

.isMobile #Navigation
	.links
		a
			&:hover
				.logo-a,
				img.logo
					opacity: 1

				img.hover
					opacity: 0

				.shadow
					opacity: 0

	.nav-links
		.show
			.links
				margin-top: 0

		.lineImg
			display: none

		.i18nBtnImg
			position: relative
			width: 10vw

	#Navs
		.navigation-links
			.playBtn
				width: 40vw

.d-flex
	display: flex

.ai-c
	align-items: center

.jc-c
	justify-content: center

.backToWebSiteImg
	width: 11.72vw
	position: relative
	cursor: pointer

.backToInventoryImg 
	width: 11.72vw
	position: relative
	cursor: pointer

	@media screen and (max-width: 1023px)
		width: 31.67vw

.mb-0
	margin-bottom: 0 !important