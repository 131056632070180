.Mint.section
	background-image: url(./assets/bg-d-min.jpeg)
	background-size: cover
	background-position: center

	.section-content
		width: 50.26vw
		height: 31.04vw
		background-image: url(./assets/mint-bg-with-fur.png)
		margin: 0 auto
		background-size: contain
		background-position: center
		background-repeat: no-repeat
		position: relative

		.counter-wrap
			position: absolute
			width: 100%
			top: 20.89vw
			display: flex
			justify-content: center
			z-index: 1

			.count
				font-size: 4.43vw
				font-weight: 700
				line-height: 5.10vw
				width: 13vw
				text-align: center
				color: white
				margin: 0 2.34vw
				// background-color: red

			.button
				width: 2.92vw
				height: 2.92vw
				background-position: center
				background-size: contain
				background-repeat: no-repeat
				margin-top: 1.09vw
				cursor: pointer

				&.decrease
					background-image: url(./assets/decrease-min.png)

				&.increase
					background-image: url(./assets/increase-min.png)
					box-shadow: 0 1.03vw 10.26vw 0 #FF56F680
					border-radius: 0.70vw

				&.disabled
					cursor: no-drop
					
		.info-wrap
			position: absolute
			display: flex
			left: 8.56vw
			bottom: 5.04vw

			&-opensale
				left: initial
				width: 100%
				align-items: center
				justify-content: center

			.info-group
				display: flex
				line-height: 1.67vw

				.t1
					color: #8EFFE1
					font-size: 1.44vw
					font-weight: 700
					margin-right: 0.52vw
					text-transform: uppercase

				.t2
					font-size: 1.65vw
					font-weight: 700
					color: white
					margin-right: 0.31vw

				.t3
					font-size: 1.24vw
					color: white

		.mint-button-area
			width: 18vw
			position: absolute
			right: -3.5vw
			top: 5vw

			img
				position: relative
				padding: 0.6vw 1vw
				&.desktop
				display: block

				&:nth-child(2)
					right: 2vw
			
				&:nth-child(3)
					right: 4vw

				&:nth-child(4)
					right: 6vw

		.mint-button
			position: absolute
			bottom: -3vw
			right: -7vw
			cursor: pointer

			&-disabled,
			&-disabled img
				cursor: no-drop !important

			img
				cursor: pointer
				&.mint-btn-disabled
					cursor: no-drop
				&.desktop
					display: block
					width: 24.69vw
					&.market-place
						position: relative
						bottom: 1vh
						right: -6vw

						@media screen and (max-width: 1023px)
							right: 0
							bottom: -4vh

						&-disabled
							cursor: no-drop

					&.mint-btn
						@media screen and (max-width: 1023px)
							bottom: -5vh
							position: relative

		.mint-button-opensale
			position: absolute
			bottom: -4vw
			width: 100%
			cursor: pointer
			display: flex
			align-items: center
			justify-content: space-between
			flex-direction: row-reverse

			@media screen and (max-width: 1662px)
				bottom: -6vw

			&-disabled,
			&-disabled img
				cursor: no-drop !important

			img
				position: relative
				cursor: pointer

				&.mint-btn-disabled
					cursor: no-drop

				&.desktop
					display: block
					padding: 0 30px

					&.market-place
						position: relative

						&-disabled
							cursor: no-drop

					&.mint-btn
						@media screen and (max-width: 1023px)
							position: relative

	.mintDialog
		position: absolute
		left: 0
		top: 0
		width: 100%
		height: 100%
		display: flex
		align-items: center
		justify-content: center
		pointer-events: none

		&-blur
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 100%
			z-index: 1
			filter: blur(0px)
			backdrop-filter: blur(0px)
			transition: all 0.3s ease-in-out
			transform-origin: center center

		&-container
			position: relative
			width: 54.26vw
			height: 34.5vw
			padding: 20px 24px
			background: url('./assets/mint-dialog-bg.png') no-repeat
			background-size: contain
			z-index: 5
			transition: all 0.3s ease-in-out
			transform-origin: center center
			transform: scale(0)

		&-header
			position: relative
			top: 0
			min-height: 3vw

			&-title-close
				position: absolute
				right: 2vw
				top: 50%
				margin-top: -7px
				display: flex
				align-items: center
				justify-content: center

				.close-icon
					width: 14px
					height: 14px
					cursor: pointer
					visibility: hidden
					opacity: 0
					transition: visibility 0s, opacity 0.5s linear

		&-content
			overflow: hidden

			.counter-wrap
				width: 100%
				display: flex
				align-items: center
				justify-content: center
				flex-wrap: wrap
				z-index: 1
				padding-top: 7.5vw

				.count
					font-size: 4.43vw
					font-weight: 700
					line-height: 5.10vw
					width: 13vw
					text-align: center
					color: white
					margin: 0 2.34vw
					// background-color: red

				.button
					width: 2.92vw
					height: 2.92vw
					background-position: center
					background-size: contain
					background-repeat: no-repeat
					margin-top: 1.09vw
					cursor: pointer

					&.decrease
						background-image: url(./assets/decrease-min.png)

					&.increase
						background-image: url(./assets/increase-min.png)
						box-shadow: 0 1.03vw 10.26vw 0 #FF56F680
						border-radius: 0.70vw

					&.disabled
						cursor: no-drop
				
				.mintPrice
					width: 100%
					margin-top: 2vw
					display: flex
					align-items: center
					justify-content: center

					.t1
						color: #8EFFE1
						font-size: 2.4vw
						font-weight: 700
						margin-right: 1.52vw
						text-transform: uppercase

					.t2
						font-size: 2vw
						font-weight: 700
						color: white
						margin-right: 1.31vw

					.t3
						font-size: 1.8vw
						color: white

				.mintBtn
					width: 100%
					margin-top: 2vw
					display: flex
					align-items: center
					justify-content: center

					&-img
						width: 20vw 

		.cyberLily-icon
			opacity: 0
		
		// 顯示彈窗
		&-show.mintDialog
			pointer-events: all

			.mintDialog
				&-blur
					background: rgb(0, 0, 0, 0.5)
					filter: blur(5px)
					backdrop-filter: blur(5px)

				&-container
					transform: scale(1)

				&-header
					.cyberLily-icon
						opacity: 1
						width: 9vw
						position: absolute
						left: 1vw
						top: -3.2vw

					.close-icon
						visibility: visible
						opacity: 1

.isPadLandscape .Mint.section
	.section-content
		width: 77.73vw
		height: 48.05vw

		.counter-wrap
			top: 33.98vw

			.count
				font-size: 6.84vw
				line-height: 7.91vw
				width: 20vw
				margin: 0 3.61vw

			.button
				width: 4.49vw
				height: 4.49vw
				margin-top: 1.76vw

				&.increase
					box-shadow: 0 0.29vw 3.22vw 0 #FF56F680
					border-radius: 1.07vw

		.info-wrap
			left: 13.44vw
			bottom: 7.34vw

			.info-group
				margin-right: 2.54vw
				line-height: 2.73vw

				.t1
					font-size: 1.76vw
					margin-right: 0.49vw

				.t2
					font-size: 2.15vw
					margin-right: 0.59vw

				.t3
					font-size: 1.66vw

		.mint-button
			bottom: -2vw
			right: 1vw

		.mint-button-area
			width: 25vw
			position: absolute
			right: -3.5vw
			top: 8vw

			img
				position: relative
				padding: 1vw
				&.desktop
				display: block

				&:nth-child(2)
					right: 3vw
			
				&:nth-child(3)
					right: 6vw

				&:nth-child(4)
					right: 9vw

	.mintDialog
		&.mintDialog-show
			.cyberLily-icon
				top: -5.8vw
				width: 12vw

.isPadPortrait .Mint.section
	background-image: url(./assets/bg-portrait-min.jpg)

	.section-content
		background-image: url(./assets/content-bg-portrait-min.png)
		width: 75.00vw
		height: 87.01vw

		.counter-wrap
			top: 60.16vw

			.count
				font-size: 8.30vw
				line-height: 9.57vw
				width: 24vw
				margin: 0 4.39vw

			.button
				width: 5.47vw
				height: 5.47vw
				margin-top: 2.3vw

				&.increase
					box-shadow: 0 0.39vw 3.91vw 0 #FF56F680
					border-radius: 1.37vw

		.info-wrap
			left: 0
			bottom: 6.15vw
			flex-wrap: wrap
			width: 100%

			.info-group
				width: 100%
				margin-right: 0
				line-height: 3.91vw
				justify-content: center

				.t1
					font-size: 1.95vw
					margin-right: 0
					width: 24.41vw

				.t2
					font-size: 2.54vw
					margin-right: 0
					width: 10.25vw
					text-align: center

				.t3
					font-size: 1.95vw
					width: 9.57vw
					text-align: center

		// .mint-button
		// 	bottom: -10vw
		// 	right: calc(50% - 20vw)

		// 	img.desktop
		// 		width: 40vw

		

.isMobile .Mint.section
	background-image: url(./assets/bg-mobile-min.jpg)
	min-height: 168.72vw

	.section-content
		background-image: url(./assets/content-bg-mobile-min.png)
		width: 85.77vw
		height: 142.85vw
		margin-bottom: 7vw

		.counter-wrap
			top: 77.69vw

			&-opensale
				top: 60.69vw

			.count
				// font-size: 17.95vw
				font-size: 16vw
				line-height: 21.28vw
				width: 42vw
				margin: 5.90vw 3vw

			.button
				width: 12.31vw
				height: 12.31vw
				margin-top: 10.6vw

				&.increase
					box-shadow: 0 1.03vw 10.26vw 0 #FF56F680
					border-radius: 2.82vw

		.info-wrap
			top: 70vw
			display: block
			left: 0
			width: 100%

			.info-group
				align-items: center
				justify-content: center
				margin-right: 0
				line-height: 6.41vw
				margin-top: 1.03vw

				.t1
					width: 34.87vw
					margin-right: 0
					font-size: 4.10vw

				.t2
					width: 15.38vw
					margin-right: 0
					font-size: 5.13vw
					text-align: center

				.t3
					font-size: 4.10vw

			&-opensale
				bottom: 24.05vw
				left: initial
				width: 100%
				display: flex
				flex-direction: column

				@media screen and (max-width: 612px)
					bottom: 22.05vw

				.info-group
					.t2
						text-align: center

					&:not(:first-child)
						color: red
						.t3
							padding-left: 2.5vw

		.mint-button
			bottom: -10.5vw
			right: 11vw

			img.desktop
				width: 60vw

			&-opensale
				flex-wrap: wrap
				bottom: -22.5vw

				@media screen and (max-width: 612px)
					bottom: -28vw

				img
					padding: 0 10vw
					padding-bottom: 2vw

		.mint-button-area
			width: 100%
			left: 0
			top: 80vw

			img
				width: 55vw
				right: inherit
				padding: 1.5vw 0
				left: 0
				margin: 0 auto

	.mintDialog
		&-header
			min-height: 5.5vw

		&-show.mintDialog
			.cyberLily-icon
				width: 23vw
				top: -11.4vw

		&-container
			position: relative
			width: 100%
			height: 65vw
			padding: 12px 24px

			.mintDialog-content
				.counter-wrap
					padding-top: 14.5vw

					.mintPrice
						margin-top: 6vw

						.t1
							font-size: 4.4vw
							margin-right: 2.52vw

						.t2
							font-size: 4vw
							margin-right: 1.31vw

						.t3
							font-size: 3.8vw

					.button
						width: 6.92vw
						height: 6.92vw

					.count
						font-size: 9.43vw
						margin: 0 9.34vw

					.mintBtn
						margin-top: 4vw

						&-img
							width: 40vw


.cursor-not-allowed
	cursor: not-allowed

.cursor-pointer
	cursor: pointer