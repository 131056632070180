.Game.section
	background-image: url(./assets/bg-d-min.jpeg)
	background-size: cover
	background-position: center

	.flex-wrap
		display: flex
		flex-wrap: wrap
		width: 69.95vw
		margin: 0 auto
		margin-top: 2.08vw

		.item:nth-of-type(1), .item:nth-of-type(2)
			margin-right: 2.45vw

		.item:nth-of-type(4), .item:nth-of-type(5), .item:nth-of-type(6)
			margin-left: 2.45vw

		.item
			width: 20.31vw
			height: 12.29vw
			background-size: contain
			background-repeat: no-repeat
			background-position: center
			display: flex
			flex-direction: column
			justify-content: center
			align-items: center
			margin-bottom: 2.45vw

			&.i1
				background-image: url(./assets/bg-d-1.svg)

			&.i2
				background-image: url(./assets/bg-d-2.svg)

			&.i3
				background-image: url(./assets/bg-d-3.svg)

			&.i4
				background-image: url(./assets/bg-d-4.svg)

			&.i5
				background-image: url(./assets/bg-d-5.svg)

			&.i6
				background-image: url(./assets/bg-d-6.svg)

			.t1, .t2
				width: 15.63vw

			.t1
				font-weight: 700
				font-size: 1.25vw
				line-height: 1.51vw
				font-style: italic
				text-shadow: 0px 0.21vw 1.04vw #FF56F6

			.t2
				margin-top: 0.42vw
				font-size: 0.83vw
				line-height: 1.25vw
				letter-spacing: 0.05em

.isPadLandscape .Game.section
	.flex-wrap
		width: 90.14vw
		margin-top: 3.42vw

		.item:nth-of-type(1), .item:nth-of-type(2)
			margin-right: 1.04vw

		.item:nth-of-type(4), .item:nth-of-type(5), .item:nth-of-type(6)
			margin-left: 0

		.item:nth-of-type(4), .item:nth-of-type(5)
			margin-right: 1.04vw

		.item
			width: 28.32vw
			height: 17.19vw
			margin-bottom: 1.56vw

			.t1, .t2
				width: 21.97vw

			.t1
				font-size: 1.76vw
				line-height: 2.15vw
				text-shadow: 0 0.29vw 1.46vw #FF56F6

			.t2
				margin-top: 0.59vw
				font-size: 1.17vw
				line-height: 1.76vw

.isPadPortrait .Game.section
	background-image: url(./assets/bg-portrait-min.jpg)

	.flex-wrap
		width: 78.13vw
		margin-top: 3.91vw

		.item:nth-of-type(1), .item:nth-of-type(2)
			margin-right: 0

		.item:nth-of-type(4), .item:nth-of-type(5), .item:nth-of-type(6)
			margin-left: 0

		.item:nth-of-type(odd)
			margin-right: 1.95vw

		.item
			width: 38.09vw
			height: 23.05vw
			margin-bottom: 1.95vw

			.t1, .t2
				width: 29.39vw

			.t1
				font-size: 2.34vw
				line-height: 2.83vw
				text-shadow: 0 0.39vw 1.95vw #FF56F6

			.t2
				margin-top: 0.78vw
				font-size: 1.56vw
				line-height: 2.34vw

.isMobile .Game.section
	background-image: url(./assets/bg-mobile-min.jpg)
	min-height: 293.85vw
	border-bottom: 1px solid #21073f

	.flex-wrap
		width: 84.62vw
		margin-top: 5.13vw

		.item:nth-of-type(1), .item:nth-of-type(2)
			margin-right: 0

		.item:nth-of-type(4), .item:nth-of-type(5), .item:nth-of-type(6)
			margin-left: 0

		.item
			width: 84.62vw
			height: 37.44vw
			margin-bottom: 1.28vw

			&.i1
				background-image: url(./assets/bg-m-1-min.png)

			&.i2
				background-image: url(./assets/bg-m-2-min.png)

			&.i3
				background-image: url(./assets/bg-m-3-min.png)

			&.i4
				background-image: url(./assets/bg-m-4-min.png)

			&.i5
				background-image: url(./assets/bg-m-5-min.png)

			&.i6
				background-image: url(./assets/bg-m-6-min.png)

			.t1, .t2
				width: 70.51vw

			.t1
				font-size: 4.62vw
				line-height: 5.64vw
				text-shadow: 0 1.03vw 4.87vw #FF56F6

			.t2
				margin-top: 1.79vw
				font-size: 3.33vw
				line-height: 5.13vw
