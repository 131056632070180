.Landing.section
	background-image: url(./assets/bg-d-min.jpeg)
	background-size: cover
	background-position: center
	overflow: hidden

	.carousel
		background-image: url(./assets/characters1-min.png)
		width: 57.45vw
		height: 44.17vw
		position: absolute
		left: 34.22vw
		bottom: -3.18vw
		background-size: contain
		background-position: center
		background-repeat: no-repeat
		animation: characters 10000ms linear infinite

	@keyframes characters
		0%
			background-image: url(./assets/characters1-min.png)
		25%
			background-image: url(./assets/characters1-min.png)
		33%
			background-image: url(./assets/characters2-min.png)
		58%
			background-image: url(./assets/characters2-min.png)
		66%
			background-image: url(./assets/characters3-min.png)
		91%
			background-image: url(./assets/characters3-min.png)

	.pointer-left
		position: absolute
		width: 7.45vw
		height: 2.97vw
		background-image: url(./assets/pointer-left.svg)
		background-size: contain
		background-position: center
		background-repeat: no-repeat
		top: 25.4vw
		left: -3vw

	.pointer-right
		position: absolute
		width: 3.75vw
		height: 4.64vw
		background-image: url(./assets/pointer-right.svg)
		background-size: contain
		background-position: center
		background-repeat: no-repeat
		top: 6.6vw
		right: 11vw

	.text-wrap
		position: absolute
		display: flex
		animation: texts 6000ms linear infinite
		white-space: nowrap

		.number
			font-size: 2.03vw
			font-weight: 800
			color: #4DFFFF
			letter-spacing: 0.1em
			opacity: 0.9
			margin-right: 0.4vw
			text-align: right
			text-shadow: 0 0 0.10vw white

		.text
			font-size: 0.78vw
			font-weight: 500
			color: white
			letter-spacing: 0.04em
			opacity: 0.8
			line-height: 0.94vw

			&-zh
				line-height: 1.2vw

	.text-wrap1, .text-wrap2
		left: 0
		top: -2.4vw

		.number
			min-width: 2.34vw

		.text
			display: flex
			flex-direction: column
			justify-content: center

	.text-wrap3, .text-wrap4
		left: 4.2vw
		top: -1.2vw

		.number
			min-width: 3.02vw

		.text
			padding-top: 0.3vw

	.text-wrap2, .text-wrap4
		animation-delay: 3000ms

	@keyframes texts
		0%
			opacity: 1
			transform: translateY(0)
		35%
			opacity: 1
			transform: translateY(0)
		50%
			opacity: 0
			transform: translateY(20px)
		85%
			opacity: 0
			transform: translateY(20px)
		100%
			opacity: 1
			transform: translateY(0)

	.title
		position: absolute
		left: 6.30vw
		top: 7.60vw

		img
			width: 35.78vw
			display: block

	.ticker
		background-image: url(./assets/ticker.svg)
		position: absolute
		bottom: 0
		left: 0
		width: 100vw
		height: 2.60vw
		background-repeat: repeat-x
		background-size: auto 100%
		animation: ticker 60000ms linear infinite

	@keyframes ticker
		from
			background-position: left center
		to
			background-position: -126.20vw center

	.announcement
		position: absolute
		width: 25vw
		left: 5vw
		bottom: 5vw
		transition: all 500ms $bezier
		z-index: 13
		background-color: transparent
		display: flex
		align-items: center
		justify-content: center

		&.fullScreen
			z-index: 101
			background-color: rgba(0, 0, 0, 0.9)
			backdrop-filter: blur(3px)
			width: 100%
			height: 100%
			bottom: 0
			left: 0

			.modal
				background-color: transparent
				transition: all 200ms $bezier
				border: none
				width: 100%
				height: initial
				background: url('./assets/announcement_bg.png') no-repeat center center
				background-size: cover
				padding: 1vw 3vw

				&__header
					display: flex

				.title
					visibility: visible
					opacity: 1

				.close
					visibility: visible
					opacity: 1
					padding-top: 20px

		.swiper
			height: 100%
			width: 100%
			overflow: visible
			padding: 1vw 0

			&-wrapper
				align-items: center

			.swiper-container
				overflow: visible

			&-slide
				z-index: -1
				border: 4px solid #4b3fff
				-webkit-box-reflect: below 22px -webkit-linear-gradient(bottom, rgba(255, 0, 0, 0.2) 0%, transparent 70px, transparent 100%)

				img
					display: block
					transform: scale(1)
					transition: transform .3s ease-in-out

				&-active
					z-index: 1

			&-button
				&-prev, &-next
					&::after
						color: #FFFFFF

			&-pagination
				bottom: -3vw

				&-bullet
					width: 0.5vw
					height: 0.5vw
					background-color: #FFFFFF
					margin: 0 0.3vw !important

		&__banner
			width: 100%
			cursor: pointer

			// &:hover + .announcement__content
			// 	background: linear-gradient(to bottom,rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 1))

		&__content
			width: 100%
			padding: 1vw 1.5vw
			box-sizing: border-box
			// background: linear-gradient(to bottom,rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.9))
			background: #FFFFFF
			transition: all .3s ease-in-out

			h2
				margin: 0
				font-weight: bold
				font-size: 24px
				line-height: 1.1

			p
				margin: 8px 0 0
				opacity: .65
				font-size: 14px
				font-weight: 600
				display: -webkit-box
				-webkit-box-orient: vertical
				-webkit-line-clamp: 2
				overflow: hidden
				text-overflow: ellipsis
				letter-spacing: 0.5px

		.modal
			position: relative
			background-color: rgba(255, 255, 255, 0.55)
			transition: all 200ms $bezier
			border: 8px solid rgb(71, 45, 209)

			&__header
				display: none
				align-items: center
				justify-content: space-between
				position: absolute
				top: -16%
				z-index: 10
				width: 100%
				left: 0
				padding: 0 2vw

			.title
				top: initial
				left: initial
				position: relative

				img
					width: initial

			.close
				border-radius: 50%
				width: 50px
				height: 50px
				display: flex
				align-items: center
				justify-content: center
				font-weight: bold
				font-size: 20px
				cursor: pointer
				visibility: hidden
				opacity: 0
				z-index: 20

				@media screen and ( max-width: 1700px )
					top: -4vw

				@media screen and ( max-width: 1500px )
					top: -5vw

				@media screen and ( max-width: 1300px )
					top: -6vw

			.youTuBePlayer
				width: 100%
				height: 100%

				.youTuBeIframe
					width: 100%
					height: 100%

		.smallAnnouncementBanner
			cursor: pointer
			display: block

.isPadLandscape .Landing.section
	.carousel
		width: 70.12vw
		height: 53.91vw
		left: 26.86vw
		bottom: -5.27vw

	.pointer-left
		width: 10.45vw
		height: 4.10vw
		top: 24vw
		left: -5vw

	.pointer-right
		width: 5.27vw
		height: 6.54vw
		top: 0
		right: 24vw

	.text-wrap
		.number
			font-size: 2.83vw
			text-shadow: 0 0 0.20vw white

		.text
			font-size: 1.07vw
			line-height: 1.27vw

	.text-wrap1, .text-wrap2
		left: 2vw
		top: -3.4vw

		.number
			min-width: 2.34vw

	.text-wrap3, .text-wrap4
		left: 6vw
		top: -1.6vw

		.number
			min-width: 5vw

		.text
			padding-top: 0.5vw

	.title
		left: 3.61vw
		top: 10.64vw

		img
			width: 47.07vw

	.ticker
		height: 3.13vw

	@keyframes ticker
		from
			background-position: left center
		to
			background-position: -151.46vw center

.isPadPortrait .Landing.section
	background-image: url(./assets/bg-portrait-min.jpg)

	.carousel
		width: 91.50vw
		height: 70.41vw
		left: -3.71vw
		bottom: -3.13vw

	.pointer-left
		width: 15.92vw
		height: 6.35vw
		top: 0
		left: 14vw

	.pointer-right
		width: 8.11vw
		height: 9.96vw
		top: 8vw
		right: 18vw

	.text-wrap
		.number
			font-size: 4.39vw
			text-shadow: 0 0 0.20vw white

		.text
			font-size: 1.66vw
			line-height: 2.05vw

	.text-wrap1, .text-wrap2
		left: 2vw
		top: -5vw

		.number
			min-width: 2.34vw

	.text-wrap3, .text-wrap4
		left: 9vw
		top: -2.4vw

		.number
			min-width: 6.54vw

		.text
			padding-top: 0.8vw

	.title
		left: 10.06vw
		top: 18.65vw

		img
			width: 76.07vw

	.ticker
		height: 4.88vw

	@keyframes ticker
		from
			background-position: left center
		to
			background-position: -236.62vw center

.isMobile .Landing.section
	background-image: url(./assets/bg-mobile-min.jpeg)
	min-height: 100vh
	min-height: 168.72vw
	padding-top: 0

	.carousel
		width: 122.31vw
		height: 94.10vw
		left: -13.59vw
		bottom: -11.03vw

	.pointer-left
		width: 17.44vw
		height: 6.92vw
		top: -5vw
		left: 25vw

	.pointer-right
		width: 8.97vw
		height: 11.03vw
		top: -12vw
		right: 50vw

	.text-wrap
		.number
			font-size: 4.87vw
			text-shadow: 0 0 0.26vw white

		.text
			font-size: 1.79vw
			line-height: 3.15vw

			&-zh
				line-height: 3.8vw

	.text-wrap1, .text-wrap2
		left: 0
		top: -7vw

		.number
			min-width: 5.64vw

	.text-wrap3, .text-wrap4
		left: 10vw
		top: -2.6vw

		.number
			min-width: 7.18vw

		.text
			padding-top: 0.8vw

	.title
		left: 5.13vw
		top: 22.05vw

		img
			width: 85.64vw

	.ticker
		height: 8.21vw

	@keyframes ticker
		from
			background-position: left center
		to
			background-position: -397.69vw center

	.announcement
		bottom: 10vw
		left: 2vw
		width: 40vw

		.modal
			border-width: 4px

		&__content
			padding: 4vw 2vw

			h2
				font-size: 16px
				overflow: hidden
				text-overflow: ellipsis
				display: -webkit-box
				-webkit-box-orient: vertical
				-webkit-line-clamp: 1
				
			p
				font-size: 12px

		&__banner,
		&__content
			object-fit: cover

		&.fullScreen
			padding: 0
			top: 0
			left: 0
			height: 100vh
			width: 100vw

			.modal
				background: url('./assets/announcement_bg_m.png') no-repeat center center
				background-size: cover
				padding: 1vw 12vw

				&__header
					top: -17%

					@media screen and ( max-width: 800px )
						top: -19%

					@media screen and ( max-width: 700px )
						top: -21%

					@media screen and ( max-width: 600px )
						top: -24%

					@media screen and ( max-width: 500px )
						top: -35%

				.close
					width: 40px
					height: 40px

					@media screen and ( max-width: 500px )
						width: 30px
						height: 30px

		.swiper
			&-slide
				-webkit-box-reflect: initial

			&-button-prev
				left: -11vw

			&-button-next
				right: -11vw

			&-pagination
				bottom: -9vw

				&-bullet
					width: 3vw
					height: 3vw
					margin: 0 1.8vw !important

.notSeenAnnouncement
	.announcement
		z-index: 999 !important

.cursor-pointer
	cursor: pointer