.FQ.section
  .section-content
    max-width: 712px
    margin: 0 auto
    
  .t1
    text-align: center
    font-weight: 700
    font-size: 3.5vw
    padding-bottom: 2.49vw

  .t2
    font-weight: 700
    font-size: 2vw
    display: flex
    cursor: pointer
    -webkit-box-pack: justify
    justify-content: space-between
    margin-bottom: 2.36vw

    &::after
      content: "⌃"
      font-size: 2vw
      height: 2vw
      max-height: 2vw
      width: 2vw
      max-width: 2vw
      font-family: "Days One"
      transform: rotate(90deg)
      transition: transform 0.2s linear
      display: flex
      align-items: center
      justify-content: center

  p
    // line-height: 2.36vw
    padding-bottom: 0
    letter-spacing: 0.05em
    margin: 0
    max-height: 0
    overflow: hidden
    transition: max-height 0.4s cubic-bezier(0.42, 0, 0.58, 1.0), opacity 0.2s linear, padding-bottom 0.3s linear
    opacity: 0

  .active
    // margin-bottom: 2.34vw

    .t2
      &::after
        transform: rotate(180deg)

    p
      padding-bottom: 2.34vw
      max-height: 500px
      opacity: 1

#App.isMobile
  .FQ.section
    .section-content
      width: 84.62vw
      max-width: inherit
      line-height: 8.49vw

    .t1
      font-size: 10.69vw
      padding: 6.49vw 0

    .t2
      font-size: 5.5vw

      &::after
        font-size: 5.5vw
        height: 5.5vw
        max-height: 5.5vw
        width: 5.5vw
        max-width: 5.5vw

    p
      padding-top: 1.49vw
      line-height: 6.36vw
      font-size: 4vw
