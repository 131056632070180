.PlayEntry.section
	background-image: url(./assets/bg-d-min.jpeg)
	background-size: cover
	background-position: center
	color: white
	display: none
	&.show
		display: flex

	.section-content
		width: 58.33vw
		margin: 0 auto
		height: 100vh
		display: flex
		align-items: center
		justify-content: center

		.entryRow
			display: flex
			align-items: center
			justify-content: center
			text-align: center

			.card
				cursor: pointer
				max-width: 20vw
				margin: 0 2vw
				transform: scale(1)
				transition: transform .3s ease-in-out

				@media screen and (max-width: 1400px)
					max-width: 30vw

				img
					width: 20vw

					@media screen and (max-width: 1400px)
						width: 30vw

.isDesktopNormal .PlayEntry.section
	.card
		&:hover
			transform: scale(1.1)

.isPadLandscape .PlayEntry.section
	.section-content
		width: 87.89vw

.isPadPortrait .PlayEntry.section
	.card
		max-width: 40vw
		img
			width: 40vw

.isMobile .PlayEntry.section
	.section-content
		height: 100%
		width: 100%
	// .entryRow
	// 	display: block

	.entryRow
		flex-wrap: wrap
		
		.card
			display: block
			margin: 20px !important

			@media screen and (max-width: 700px)
				margin: 20px 0 !important

			img
				min-height: 185px

	.entryRow
		@media screen and (max-width: 600px)
			flex-wrap: wrap

			.card
				margin: 1vh 0
				min-width: 300px
				width: 300px

		.card
			max-width: 100%
			img
				width: 100%
