#VIDEOsection
  background-color: #230b49
  // background: url('./assets/video_bg.png') center center no-repeat
  background-image: url(./assets/bg-d-min.png)
  background-size: cover
  background-position: center

  .videoContent
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    
    &__player
      width: 1200px
      height: 600px

      @media screen and (max-width: 1300px)
        width: 900px
        height: 450px

      @media screen and (max-width: 1023px)
        width: 600px
        height: 300px

      @media screen and (max-width: 700px)
        width: 100%

  .youTuBePlayer
    width: 100%
    height: 100%

    .youTuBeIframe
      width: 100%
      height: 100%

.isMobile .VIDEOsection
  background-image: url(./assets/bg-mobile-min.png)