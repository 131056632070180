@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Bungee&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Bungee&family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500&display=swap')
@import 'variables'
@import 'mixins'

html *
	box-sizing: border-box
	outline: none
	// font-family: 'Montserrat', sans-serif
	font-family: 'Noto Sans TC', sans-serif

body
	margin: 0
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	overflow-x: hidden
	background-color: #21073F

img
	width: 100%

.preload-images
	position: fixed
	left: 101vw
	top: 0

section.section
	position: relative

.isDesktopNarrow section.section
	padding: 6vw 0

.isDesktopNormal > div:nth-child(2)
	height: 100vh

#App
	#fullpage
		// .fp-slide, .fp-tableCell, .fp-section
		// 	@media screen and (max-width: 1024px)
		// 		height: 48.85vw !important

		// .fp-auto-height.fp-section, .fp-auto-height .fp-slide, .fp-auto-height .fp-tableCell
		// 	height: auto !important

		// .fp-slide, .fp-tableCell, .fp-section
		// 	@media screen and (max-width: 768px)
		// 		height: auto !important

		.wrapper
			transform: translate3d(0,0,0)
			-webkit-transform: translate3d(0,0,0)

		.slide
			transform: translate3d(0,0,0)
			-webkit-transform: translate3d(0,0,0)

		.section
			transform: translate3d(0,0,0)
			-webkit-transform: translate3d(0,0,0)

		.section-content
			transform: translate3d(0,0,0)
			-webkit-transform: translate3d(0,0,0)

		.section-content
			color: white

			.title
				font-weight: 700
				font-size: 2.34vw
				line-height: 2.86vw
				text-align: center

			.sub
				width: 43.85vw
				text-align: center
				font-size: 0.83vw
				line-height: 150%
				letter-spacing: 0.05em
				margin: 0 auto
				margin-top: 0.52vw

	.notSeenVideo
		#fullpage
			transform: none!important

			.section
				transform: none!important
				-webkit-transform: none!important

	.market_place_content,
	.market_place_detail_content
		background-image: url(../sections/Mint/assets/bg-d-min.jpeg)
		background-size: cover
		background-position: center
		height: 100vh
		width: 100vw

#App.isPadLandscape #fullpage
	.section-content
		.title
			font-size: 2.44vw
			line-height: 3.03vw

		.sub
			width: 72.75vw
			font-size: 1.27vw
			line-height: 1.95vw
			margin-top: 0.98vw

#App.isPadPortrait #fullpage
	.section-content
		.title
			font-size: 4.39vw
			line-height: 5.37vw

		.sub
			width: 75.00vw
			font-size: 1.56vw
			line-height: 2.34vw
			margin-top: 0.98vw

#App.isMobile
	#fullpage
		overflow-y: hidden
		section.section .fp-tableCell,
		section.section .fp-overflow
			// height: auto !important
			height: initial !important
			overflow: initial !important
			max-height: initial !important

		.section-content
			.title
				font-size: 7.69vw
				line-height: 9.49vw

			.sub
				width: 84.62vw
				font-size: 3.33vw
				line-height: 4.36vw
				margin-top: 2.56vw

	.market_place_content,
	.market_place_detail_content
		background-image: url(../sections/Mint/assets/bg-mobile-min.jpg)
		min-height: 168.72vw

	.market_place_detail_content
		background-size: auto

#fp-nav ul li a span,
.fp-slidesNav ul li a span
	background: #FFFFFF !important

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button
	-webkit-appearance: none
	margin: 0

/* Firefox */
input[type=number]
	// -moz-appearance: textfield

@import './slick'
@import './utils'
@import '../components/Navigation/Navigation'
@import '../components/Footer/Footer'
@import '../components/Alert/Alert'
@import '../components/Loading/Loading'
@import '../components/MintButton/MintButton'
@import '../components/AcceptCookie/AcceptCookie'
@import '../components/ScrollDown/ScrollDown'
@import '../sections/WebSide/WebSide'
@import '../sections/VideoArea/VideoArea'
@import '../sections/Landing/Landing'
@import '../sections/About/About'
@import '../sections/NFT/NFT'
@import '../sections/Mint/Mint'
@import '../sections/Game/Game'
@import '../sections/LHC/LHC'
@import '../sections/Roadmap/Roadmap'
@import '../sections/Team/Team'
@import '../sections/F&Q/F&Q'
@import '../sections/PlayEntry/PlayEntry'
