.dialog
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  pointer-events: none

  &-disclaimer
    &.dialog
      z-index: 102
      &-show.dialog
        z-index: 102

    .dialog
      &-container
        width: 40.26vw
        height: 30.5vw
        background-color: #00000045

      &-content
        padding-right: 30px

        .title
          font-size: 24px
          font-weight: bold

        .itemList
          &-item:not(:last-child)
            margin-bottom: 2vh

  // 顯示彈窗
  &-show.dialog
    pointer-events: all
    z-index: 101

    .dialog
      &-blur
        background: rgb(0, 0, 0, 0.7)
        filter: blur(5px)
        backdrop-filter: blur(5px)

      &-container
        transform: scale(1)

  &-blur
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 1
    filter: blur(0px)
    backdrop-filter: blur(0px)
    transition: all 0.3s ease-in-out
    transform-origin: center center

  &-userInfo
    position: absolute
    top: 16px
    left: 10px
    color: #FFF
    display: flex
    align-items: center
    font-weight: 500
    font-size: 20px

    .BNB-chain-icon
      width: 40px
      margin-right: 4px

  &-buttons
    position: absolute
    top: 20px
    right: 14px
    color: #FFF

    .dialog-button
      font-size: 1.6rem
      cursor: pointer

      &:not(:last-child)
        margin-right: 16px

      > a
        color: #FFF

  &-container
    position: relative
    width: 54.26vw
    height: auto
    max-height: calc(100% - 20vh)
    padding: 70px 12px 20px 24px
    z-index: 5
    transition: all 0.3s ease-in-out
    transform-origin: center center
    transform: scale(0)
    background-color: #00000045
    box-sizing: border-box
    overflow: hidden
    border-radius: 10px
    display: flex
    flex-direction: column

    &::after
      content: ""
      position: absolute
      inset: 0
      border-radius: 10px
      padding: 1.5px
      background: linear-gradient(to bottom, #e281ff, #ffffff)
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
      -webkit-mask-composite: xor
      mask-composite: exclude
      pointer-events: none

    &-disclaimer
      padding: 20px 24px

  &-content
    color: #FFF
    height: 100%
    display: flex
    flex-direction: column
    margin-top: 1vh
    overflow: auto
    padding-right: 12px

    &::-webkit-scrollbar
      width: 4px

    &::-webkit-scrollbar-button
      background: transparent
      border-radius: 4px

    &::-webkit-scrollbar-track-piece
      background: #494949
      border-radius: 4px

    &::-webkit-scrollbar-thumb
      border-radius: 4px
      background-color: #FFF
      border: 1px solid FFF

    &::-webkit-scrollbar-track
      box-shadow: transparent

    .banner
      display: flex
      align-items: center
      justify-content: center
      padding: 10px 0
      font-size: 1.5rem
      margin-bottom: 2vh
      background: url(./assets/titleBg.png) no-repeat center center
      background-size: contain

      &-image
        width: auto

    .setting-area
      display: grid
      grid-template-columns: minmax(0, 1fr) 100px minmax(0, 1fr)
      text-align: center
      grid-gap: 1vw
      margin-bottom: 2vh

      @media screen and (max-width: 1400px)
        display: flex
        flex-direction: column

      > div
        position: relative
        padding: 10px
        display: flex
        flex-direction: column
        align-items: center
        border-radius: 10px

        &:not(.arrow-icon)
          background: #00000045

          &::after
            content: ""
            position: absolute
            inset: 0
            border-radius: 10px
            padding: 1.5px
            background: linear-gradient(to right, #ffffff, #e281ff)
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
            -webkit-mask-composite: xor
            mask-composite: exclude
            pointer-events: none

        &:nth-child(2)
          justify-content: center
          padding: 0
          border: none
          transition: transform 0.3s ease-in-out
          transform: rotate(0deg)

          @media screen and (max-width: 1400px)
            transform: rotate(90deg)

          .arrowArea
            border-radius: 50%
            padding: 4px 10px
            background-color: #FFF
            color: #000

      .inputArea
        margin: 16px 0

        input
          max-width: 200px

        &-sell
          display: grid
          align-items: center
          justify-content: center
          grid-template-columns: 2fr 80px
          grid-gap: 1vw
          grid-auto-flow: column

        &-number
          background: none
          text-align: center
          font-size: 1.5rem
          color: #FFF
          border-image-source: url(./assets/input-line.png)
          border-image-slice: 0 0 100% 0
          border-image-width: 1

        &-remaining
          display: flex
          flex-direction: column
          align-items: center

          &-text
            margin-bottom: 5px
            display: flex
            align-items: center

            .remainingSlash
              margin-right: 5px

            .remainingNumber
              max-width: 100px
              display: inline-block

          &-maxBtn
            background-color: #b852ff
            border: none
            color: #FFF
            padding: 4px 16px
            cursor: pointer
            font-weight: bold
            border-radius: 10px

      .iconTitle
        display: flex
        align-items: center
        justify-content: center
        margin: 16px 0
        font-weight: 500
        font-size: 24px

        &-img
          width: 30px
          margin-right: 10px

      .remindText
        color: red

      .BNBExchangeValue
        font-size: 1.5rem
        margin-bottom: 0

        .inputArea-remaining
          flex-direction: row

          &-text
            margin-left: 5px

          small
            margin-bottom: 0

    .confirm-area
      &-image
        padding: 0 10vw
        cursor: pointer

        &-disabled
          cursor: not-allowed
      // &-btn
      //   padding: 10px 0
      //   width: 100%
      //   font-size: 1.2rem
      //   border: none
      //   font-weight: bold
      //   border: 2px solid #FFF
      //   cursor: pointer
      //   color: #FFF

      //   &-sell
      //     background-color: red

      //   &-buy
      //     background-color: #07a507

      //   &:disabled
      //     border-color: #d9d9d9
      //     color: rgba(0,0,0,.25)
      //     background-color: #a7a7a7
      //     cursor: not-allowed

    .orderList-tools
      margin-bottom: 1vh
      display: flex
      align-items: center
      justify-content: flex-end
      font-size: 24px

      i
        color: #FFFFFF
        cursor: pointer

        &:not(:last-child)
          margin-right: 12px

    .orderList-area
      position: relative
      padding: 30px
      height: 100%
      border-radius: 10px

      &::after
        content: ""
        position: absolute
        inset: 0
        border-radius: 10px
        padding: 1px
        background: linear-gradient(to right, #ffffff8c, #e281ff8c)
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
        -webkit-mask-composite: xor
        mask-composite: exclude
        pointer-events: none

      &-loading
        display: flex
        align-items: center
        justify-content: center
        height: 100%

      &-noData,
      &-loading
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        height: 100%
        min-height: 200px

        .noDataImg
          width: 10vw

        .noDataText
          font-size: 2rem
          margin: 0

      &-withData
        position: relative
        padding: 20px
        border-radius: 10px

        &::after
          content: ""
          position: absolute
          inset: 0
          border-radius: 10px
          padding: 1.5px
          background: linear-gradient(to right, #ffffff, #e281ff)
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
          -webkit-mask-composite: xor
          mask-composite: exclude
          pointer-events: none

        &:not(:last-child)
          margin-bottom: 1vh

        .withDataContent
          display: flex
          align-items: center
          justify-content: space-between
          font-weight: bold

          .withDataInfo
            display: flex
            flex-direction: column

            &-unit
              margin-left: 5px

          .withDataButton
            border: none
            padding: 10px
            background-color: #ffba00
            font-weight: bold
            border-radius: 5px
            cursor: pointer
            text-transform: uppercase

.isMobile
  .dialog
    height: 100vh

    &-show.dialog
      .dialog
        &-blur
          background-color: #000

    &-container
      width: 100%
      height: 100%
      max-height: initial

      .setting-area
        grid-gap: 5vw

        .inputArea
          &-number
            max-width: 200px

    &-content
      .orderList-area
        padding: 20px

        &-withData
          padding: 10px

          .timeInfo
            margin-top: 1vh

    &-disclaimer
      .dialog-container
        width: calc(100% - 20vw)
        height: calc(100% - 20vh)
        padding: 50px 12px 10px 12px

      &.dialog-show.dialog
        .dialog
          &-blur
            background-color: rgb(0, 0, 0, 0.5)
