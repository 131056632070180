.NFT.section
	background-image: url(./assets/bg-d-min.jpeg)
	background-size: cover
	background-position: center
	color: white

	.flex-wrap
		display: flex
		justify-content: center

		.center
			position: relative
			margin: 0 2.40vw

			img
				width: 35.31vw
				display: block
				margin-top: 0.73vw

			.box
				position: absolute
				display: flex

				.number
					color: #8EFFE1
					opacity: 0.9
					font-weight: 800
					font-size: 2.03vw
					letter-spacing: 0.1em
					margin-right: 0.52vw
					text-shadow: 0 0 0.10vw white

				.text
					color: white
					opacity: 0.8
					letter-spacing: 0.04em
					font-size: 0.78vw
					line-height: 0.94vw
					margin-top: 0.3vw
					font-weight: 500
					white-space: nowrap

					&-zh
						line-height: 1.2vw

			.box1
				left: 9%
				top: 9%

				&.box-zh
					top: 8%

			.box2
				left: 5%
				top: 56.5%

				&.box-zh
					top: 55.5%

			.box3
				left: 69%
				top: 8%

				&.box-zh
					top: 6%

			.box4
				left: 80%
				top: 57.5%

				.text
					margin-top: 0.8vw

		.box-wrap
			.box
				width: 13.54vw
				height: 14.58vw
				background: linear-gradient(351.19deg, rgba(14, 14, 14, 0.6) 3.5%, rgba(0, 0, 0, 0.144) 87.65%)
				backdrop-filter: blur(100px)
				border-radius: 1.04vw
				padding: 0 1.82vw
				border: 0.10vw solid rgba(white, 0.3)

				&:nth-of-type(2)
					margin-top: 1.04vw

				img
					display: block
					width: 9.90vw
					max-width: 100%
					margin: 0 auto

				.t1
					text-align: center
					font-weight: 700
					font-size: 0.83vw
					line-height: 1.35vw
					letter-spacing: 0.05em

				.t2
					text-align: center
					font-size: 0.83vw
					line-height: 1.35vw
					letter-spacing: 0.05em
					white-space: nowrap

			.b1
				padding-top: 1.35vw

				.t1
					margin-top: -0.21vw

			.b2
				padding-top: 1.56vw

				.t1
					margin-top: 0.47vw

			.b3
				padding-top: 1.56vw

				.t1
					margin-top: 0.47vw

			.b4
				padding-top: 2.14vw
				padding-left: 1.04vw
				padding-right: 1.04vw

				&.TBA
					padding-bottom: 2.14vw
					display: flex
					align-items: center
					justify-content: center
					font-weight: 700

					.datetime
						border-bottom: none

				.t2
					span
						font-weight: 700

			.datetime
				display: flex
				margin-top: 0.83vw
				justify-content: center
				margin-bottom: 0.83vw
				padding-bottom: 0.42vw
				border-bottom: 0.05vw solid rgba(white, 0.2)

				.TBA
					color: #8EFFE1
					text-align: center
					font-size: 2.5vw
					letter-spacing: 0.01em
					font-weight: 600
					line-height: 0.94vw
					padding: 1vh 0
					white-space: nowrap

				.date
					color: #8EFFE1
					min-width: 3.23vw
					text-align: center

					.month
						font-size: 0.94vw
						letter-spacing: 0.05em
						font-weight: 600
						line-height: 0.94vw

					.day
						font-weight: 700
						letter-spacing: 0.05em
						font-size: 1.56vw
						line-height: 1.56vw

				.time
					display: flex
					flex-direction: column
					justify-content: center
					align-items: center
					font-weight: 700
					font-size: 2.19vw
					min-width: 6.88vw
					letter-spacing: 0.05em

.isPadLandscape .NFT.section
	.flex-wrap
		.center
			margin: 0

			img
				width: 53.22vw
				margin-top: 0.98vw

			.box
				.number
					font-size: 3.32vw
					margin-right: 0.88vw
					text-shadow: 0 0 0.20vw white

				.text
					font-size: 1.27vw
					line-height: 1.56vw
					margin-top: 0.6vw

			.box1
				left: 9%
				top: 8%

			.box2
				left: 5%
				top: 55.5%

			.box3
				left: 66%
				top: 7%

			.box4
				left: 80%
				top: 56.5%

				.text
					margin-top: 1.3vw

		.box-wrap
			.box
				width: 20.31vw
				height: 21.88vw
				border-radius: 1.56vw
				padding: 0 1.56vw
				border: 0.20vw solid rgba(white, 0.3)

				&:nth-of-type(2)
					margin-top: 1.46vw

				img
					width: 14.84vw

				.t1
					font-size: 1.27vw
					line-height: 2.05vw

				.t2
					font-size: 1.27vw
					line-height: 2.05vw

			.b1
				padding-top: 1.35vw

				.t1
					margin-top: -0.4vw

			.b2
				padding-top: 2.34vw

				.t1
					margin-top: 0.68vw

			.b3
				padding-top: 2.34vw

				.t1
					margin-top: 0.68vw

			.b4
				padding-top: 3.22vw
				padding-left: 1.56vw
				padding-right: 1.56vw

			.datetime
				margin-top: 1.27vw
				margin-bottom: 1.27vw
				padding-bottom: 0.59vw
				border-bottom: 0.10vw solid rgba(white, 0.2)

				.date
					min-width: 4.79vw

					.month
						font-size: 1.46vw
						line-height: 1.76vw

					.day
						font-size: 2.34vw
						line-height: 2.83vw

				.time
					font-size: 3.42vw
					min-width: 10.35vw

.isPadPortrait .NFT.section
	background-image: url(./assets/bg-portrait-min.jpg)

	.flex-wrap
		flex-direction: column

		.wrap1
			order: 2
			display: flex
			justify-content: center
			margin-bottom: 0.98vw
			margin-top: 2.93vw

		.wrap2
			order: 3
			display: flex
			justify-content: center

		.center
			order: 1
			width: 66.21vw
			margin: 0 auto

			img
				width: 100%
				margin-top: 1.46vw

			.box
				.number
					font-size: 3.81vw
					margin-right: 0.98vw
					text-shadow: 0 0 0.20vw white

				.text
					font-size: 1.46vw
					line-height: 1.76vw
					margin-top: 0.7vw

			.box1
				left: 9%
				top: 8%

			.box2
				left: 5%
				top: 55.5%

			.box3
				left: 70%
				top: 7%

			.box4
				left: 80%
				top: 56.5%

				.text
					margin-top: 1.5vw

		.box-wrap
			.box
				width: 38vw
				height: 9.86vw
				border-radius: 1.46vw
				padding: 0
				border: 0.20vw solid rgba(white, 0.3)
				display: flex

				&:nth-of-type(2)
					margin-top: 0
					margin-left: 1.95vw

				img
					width: 9.47vw
					margin: 0
					margin-right: 0.98vw

				.t1
					font-size: 1.27vw
					line-height: 1.95vw
					text-align: left

				.t2
					font-size: 1.27vw
					line-height: 1.95vw
					text-align: left

			.va
				display: flex
				flex-direction: column
				justify-content: center

			.b1
				padding-top: 0
				padding-left: 3.13vw

				.t1
					margin-top: 0

			.b2
				padding-top: 0
				padding-left: 3.13vw

				.t1
					margin-top: 0

			.b3
				padding-top: 0
				padding-left: 3.13vw

				.t1
					margin-top: 0

			.b4
				padding-top: 0
				padding-left: 1.76vw
				padding-right: 1.76vw

			.datetime
				margin-top: 0.59vw
				margin-bottom: 0
				padding-bottom: 0
				border-bottom: none
				margin-right: 0.98vw

				.date
					min-width: 4.98vw

					.month
						font-size: 1.37vw
						line-height: 1.66vw

					.day
						font-size: 1.95vw
						line-height: 2.34vw

				.time
					font-size: 2.93vw
					min-width: 10.35vw

.isMobile .NFT.section
	background-image: url(./assets/bg-mobile-min.jpg)
	min-height: 261.54vw

	.section-content .sub
		margin-bottom: 2.56vw !important

	.flex-wrap
		flex-direction: column

		.wrap1
			order: 2
			display: block
			margin-bottom: 0
			margin-top: 4vw

		.wrap2
			order: 3
			display: block

		.center
			order: 1
			width: 100vw
			margin: 0 auto

			img
				width: 100%
				margin-top: 4vw

			.box
				display: block

				.number
					font-size: 6.67vw
					line-height: 8.21vw
					margin-right: 0
					text-shadow: 0 0 0.26vw white

				.text
					font-size: 2.56vw
					line-height: 3.08vw
					margin-top: 0

					&-zh
						line-height: 4.08vw

			.box1
				left: 9%
				top: 3%

				&.box-zh
					top: 5%

			.box2
				left: 5%
				top: 48.5%

				&.box-zh
					top: 52%

			.box3
				left: 70%
				top: 2%

				&.box-zh
					top: -2%

			.box4
				left: 80%
				top: 52.5%

				&.box-zh
					top: 52%

				.text
					margin-top: 0

		.box-wrap
			.box
				width: 92.31vw
				height: 25.90vw
				border-radius: 3.85vw
				padding: 0
				border: 0.51vw solid rgba(white, 0.3)
				margin: 0 auto
				margin-bottom: 2vw
				display: flex

				&:nth-of-type(2)
					margin-top: 0
					margin-left: auto

				img
					width: 24.87vw
					margin: 0
					margin-right: 2.56vw

				.t1
					font-size: 3.33vw
					line-height: 5.13vw
					text-align: left

				.t2
					font-size: 3.33vw
					line-height: 5.13vw
					text-align: left

			.va
				display: flex
				flex-direction: column
				justify-content: center

			.b1
				padding-top: 0
				padding-left: 7.95vw

				.t1
					margin-top: 0

			.b2
				padding-top: 0
				padding-left: 7.95vw

				.t1
					margin-top: 0

			.b3
				padding-top: 0
				padding-left: 7.95vw

				.t1
					margin-top: 0

			.b4
				padding-top: 0
				padding-left: 3vw
				padding-right: 3vw

				.t2.sp
					font-size: 2.82vw

			.datetime
				margin-top: 1.79vw
				margin-bottom: 0
				padding-bottom: 0
				border-bottom: none
				margin-right: 2.56vw

				.date
					min-width: 12.56vw

					.month
						font-size: 3.59vw
						line-height: 4.36vw

					.day
						font-size: 5.13vw
						line-height: 6.15vw

				.time
					font-size: 7.69vw
					min-width: 21.79vw
