.Team.section
	background-image: url(./assets/bg-d-min.jpeg)
	background-size: cover
	background-position: center

	.slider-wrap
		width: 82.29vw
		margin: 0 auto
		margin-top: 4.01vw
		position: relative

		.item
			width: 16.46vw
			transition: all 500ms $bezier

			img
				display: block
				width: 100%

		.slick-list
			-webkit-mask-image: linear-gradient(to right, rgba(black,0) 0%, rgba(black,1) 30%, rgba(black,1) 70%, rgba(black,0) 100%)

	.arrow
		position: absolute
		top: 10.42vw
		cursor: pointer
		z-index: 1

		&.prev
			left: -3vw

		&.next
			right: -3vw

		img
			display: block
			width: 2.92vw

	.slick-dots
		position: absolute
		left: 0
		width: 100%
		bottom: -3.96vw
		display: flex !important
		justify-content: center
		margin: 0
		padding: 0

		li
			padding: 0
			margin: 0
			list-style-type: none
			cursor: pointer

			.custom-dot-wrap
				padding: 0.21vw

				.custom-dot
					width: 0.78vw
					height: 0.78vw
					border: 0.16vw solid rgba(white, 0.3)
					border-radius: 50%

		li.slick-active
			.custom-dot-wrap .custom-dot
				border: none
				background: linear-gradient(91.9deg, #FFFFFF -16.29%, #E281FF 70.22%, #D752FF 102.91%, #FBEFFF 102.91%)

.isPadLandscape .Team.section
	.slider-wrap
		width: 100vw
		margin-top: 0.29vw

		.item
			width: 26.86vw

		.slick-list
			-webkit-mask-image: linear-gradient(to right, rgba(black,0) 0%, rgba(black,1) 30%, rgba(black,1) 70%, rgba(black,0) 100%)

	.arrow
		top: 13vw

		&.prev
			left: 4.79vw

		&.next
			right: 4.79vw

		img
			width: 4.59vw

.isPadPortrait .Team.section
	background-image: url(./assets/bg-portrait-min.jpg)

	.slider-wrap
		width: 100vw
		margin-top: 0.29vw

		.item
			width: 30.18vw

		.slick-list
			-webkit-mask-image: linear-gradient(to right, rgba(black,0) 0%, rgba(black,1) 40%, rgba(black,1) 60%, rgba(black,0) 100%)

	.arrow
		top: 18vw

		&.prev
			left: 2.93vw

		&.next
			right: 2.93vw

		img
			width: 5.47vw

.isMobile .Team.section
	background-image: url(./assets/bg-mobile-min.jpg)
	min-height: 125.90vw
	max-height: 140vw

	.slider-wrap
		width: 100vw
		margin-top: 1.03vw

		.slick-slider
			min-height: 92.31vw

		.item
			width: 66.15vw

		.slick-list
			-webkit-mask-image: linear-gradient(to right, rgba(black,0) 0%, rgba(black,1) 30%, rgba(black,1) 70%, rgba(black,0) 100%)

	.arrow
		top: 36vw

		&.prev
			left: 5.13vw

		&.next
			right: 5.13vw

		img
			width: 11.54vw

	.slick-dots
		bottom: 0

		li
			.custom-dot-wrap
				padding: 1.03vw

				.custom-dot
					width: 3.85vw
					height: 3.85vw
					border: 0.51vw solid rgba(white, 0.3)
					border-radius: 50%

		li.slick-active
			.custom-dot-wrap
				.custom-dot
					border: none
